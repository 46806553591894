/* ProjectExpand.css */

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5) translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: scale(0.5) translate(-50%, -50%);
  }
}

.project-expand-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999; /* Ensure it is behind the modal but above other content */
}

/* Light mode */
.App.light .project-expand-overlay {
  backdrop-filter: blur(5px); /* Strong blur effect */
}

.App.dark .project-expand-overlay {
  background: rgba(
    18,
    18,
    18,
    0.5
  ); /* Dark mode background color with opacity */
  backdrop-filter: blur(5px); /* Strong blur effect */
}

.project-expand-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 80vh;
  transform: translate(-50%, -50%);
  background-color: var(--dark-background);
  color: var(--dark-text-color);
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--dark-shadow);
  overflow: hidden;
  z-index: 1000;
  animation: fadeInScale 0.5s ease-out;
  transform-origin: center; /* Ensure the animation scales from the center */
}

.project-expand-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.project-expand-close {
  position: absolute;
  top: 0px;
  right: 30px;
  background: none;
  border: none;
  font-size: 48px; /* Twice the size */
  color: var(--dark-text-color);
  cursor: pointer;
  z-index: 1001;
  font-weight: bold; /* Bold text */
}

.project-expand-title-section {
  width: 100%;
  padding: 20px 0;
  background-color: var(--dark-accent-opposite-color);
  border-bottom: 2px solid var(--dark-border-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-expand-title-wrapper {
  flex-grow: 1; /* Ensures the wrapper takes up all available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 80px; /* Ensure title is not too close to the edges */
}

.project-expand-title {
  font-size: 32px;
  margin: 0;
}

.project-expand-category {
  font-size: 18px;
  color: var(--dark-text-color);
  font-weight: bold;
}

.project-expand-description {
  font-size: 18px;
  color: var(--dark-typewriter-color);
  max-width: 800px;
  margin: 0;
  font-weight: bold;
}

.project-expand-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 48px; /* Twice the size */
  cursor: pointer;
  z-index: 1001;
  font-weight: bold; /* Bold text */
  color: var(--dark-text-color); /* Ensure the color matches the text color */
}

.project-expand-prev {
  top: 120px;
  left: 20px; /* Fixed position closer to the border */
}

.project-expand-next {
  top: 120px;
  right: 20px; /* Fixed position closer to the border */
}

.project-expand-main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.project-expand-text-section,
.project-expand-image-section {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.project-expand-sections {
  margin-bottom: 40px;
}

.project-expand-section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: var(--dark-accent-color);
  border-radius: 10px;
  text-align: left; /* Ensure text starts from the left */
}

.project-expand-section-header {
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--dark-text-color);
  text-align: center; /* Center the subtitle */
}

.project-expand-section-text {
  font-size: 16px;
  color: var(--dark-text-color);
}

.project-expand-list-content {
  font-size: 16px;
  color: var(--dark-text-color);
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
}

.project-expand-list-item {
  margin-bottom: 20px;
}

.project-expand-item-subheader {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--dark-text-color);
  text-align: left; /* Ensure text starts from the left */
}

.project-expand-item-details {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin-left: 0; /* Remove left margin */
  color: var(--dark-text-color);
}

.project-expand-detail {
  margin-bottom: 5px;
}

.project-expand-takeaway {
  width: 100%;
  background-color: var(--dark-accent-opposite-color);
  padding: 20px 0;
  text-align: center;
  border-top: 2px solid var(--dark-border-color);
}

.project-expand-takeaway-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--dark-text-color);
}

.project-expand-takeaway-subtitle,
.project-expand-takeaway-content {
  font-size: 16px;
  color: var(--dark-text-color);
  max-width: 800px;
  margin: 0 auto;
  text-align: left; /* Ensure text starts from the left */
}

.project-expand-image-gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.project-expand-section-image {
  max-width: 100%;
  max-height: calc(80vh - 160px); /* Adjusting for modal padding and spacing */
  height: auto;
  border-radius: 10px;
  object-fit: contain;
}

/* Custom scrollbar styles */
.project-expand-text-section::-webkit-scrollbar,
.project-expand-image-section::-webkit-scrollbar {
  width: 12px; /* Set width of the scrollbar */
}

.project-expand-text-section::-webkit-scrollbar-track,
.project-expand-image-section::-webkit-scrollbar-track {
  background: var(--dark-background); /* Match background color for dark mode */
  padding: 0; /* Ensure no padding */
  border-radius: 0; /* Remove border radius */
}

.project-expand-text-section::-webkit-scrollbar-thumb,
.project-expand-image-section::-webkit-scrollbar-thumb {
  background: var(--dark-accent-color); /* Set thumb color to accent color */
  border-radius: 0; /* Remove border radius */
}

/* Light mode styles for the scrollbar */
.App.light .project-expand-text-section::-webkit-scrollbar-track,
.App.light .project-expand-image-section::-webkit-scrollbar-track {
  background: var(
    --light-text-color
  ); /* Match background color for light mode */
  padding: 0; /* Ensure no padding */
  border-radius: 0; /* Remove border radius */
}

.App.light .project-expand-text-section::-webkit-scrollbar-thumb,
.App.light .project-expand-image-section::-webkit-scrollbar-thumb {
  background: var(--light-accent-color); /* Set thumb color to accent color */
  border-radius: 0; /* Remove border radius */
}

/* Mozilla Firefox scrollbar styles */
.project-expand-text-section,
.project-expand-image-section {
  scrollbar-width: thin;
  scrollbar-color: var(--dark-accent-color) var(--dark-background); /* Thumb and track colors for dark mode */
}

.App.light .project-expand-text-section,
.App.light .project-expand-image-section {
  scrollbar-color: var(--light-accent-color) var(--light-background); /* Thumb and track colors for light mode */
  font-weight: 600;
}

.App.dark .project-expand-text-section,
.App.dark .project-expand-image-section {
  scrollbar-color: var(--dark-accent-color) var(--dark-background); /* Thumb and track colors for light mode */
  font-weight: 600;
}

/* Light mode styles */
.App.light .project-expand-modal {
  background-color: var(--light-background);
  color: var(--light-text-color);
  box-shadow: 0 4px 10px var(--light-shadow);
}

.App.light .project-expand-close {
  color: var(--light-text-color);
}

.App.light .project-expand-prev,
.App.light .project-expand-next {
  color: var(--light-text-color);
}

.App.light .project-expand-title-section {
  background-color: var(--light-accent-opposite-color);
  border-bottom-color: var(--light-border-color);
}

.App.light .project-expand-title {
  color: var(--light-text-color);
}

.App.light .project-expand-category {
  color: var(--light-text-color);
}

.App.light .project-expand-description {
  color: var(--light-typewriter-color);
}

.App.light .project-expand-section {
  background-color: var(--light-accent-color);
}

.App.light .project-expand-section-header,
.App.light .project-expand-section-text,
.App.light .project-expand-list-content,
.App.light .project-expand-item-subheader,
.App.light .project-expand-item-details,
.App.light .project-expand-detail {
  color: var(--light-text-color);
}

.App.light .project-expand-takeaway {
  background-color: var(--light-accent-opposite-color);
  border-top-color: var(--light-border-color);
}

.App.light .project-expand-takeaway-title,
.App.light .project-expand-takeaway-subtitle,
.App.light .project-expand-takeaway-content {
  color: var(--light-text-color);
}

.project-expand-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0; /* Ensure consistent spacing */
  background-color: var(
    --dark-accent-opposite-color
  ); /* Matches modal styling */
}

.contact-button {
  background-color: var(--dark-accent-color);
  color: var(--dark-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.contact-button:hover {
  background-color: var(--dark-accent-color-hover);
}

/* Light mode styles */
.App.light .project-expand-contact {
  background-color: var(
    --light-accent-opposite-color
  ); /* Matches modal styling */
}

.App.light .contact-button {
  background-color: var(--light-accent-color);
  color: var(--light-text-color);
  font-weight: bold;
}

.App.light .contact-button:hover {
  background-color: var(--light-accent-color-hover);
}

.toggle-button {
  display: none; /* Hide toggle button by default */
  background-color: var(--dark-accent-color);
  color: var(--dark-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px; /* Added margin to separate from contact button */
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.toggle-button:hover {
  background-color: var(--dark-accent-color-hover);
}

/* Light mode styles */
.App.light .toggle-button {
  background-color: var(--light-accent-color);
  color: var(--light-text-color);
}

.App.light .toggle-button:hover {
  background-color: var(--light-accent-color-hover);
}

/* Media queries for responsiveness */

/* For tablets and below */
@media (max-width: 768px) {
  .project-expand-modal {
    width: 90vw;
    height: 70vh; /* Adjusted to 70% for smaller height */
    padding: 10px;
    top: 20%; /* Start 20% from the top */
    left: 50%;
    transform: translate(-50%, 0); /* Adjust translate to center horizontally */
  }

  .project-expand-title-section {
    padding: 10px 0;
  }

  .project-expand-title-wrapper {
    padding: 0 40px; /* Ensure title is not too close to the edges */
  }

  .project-expand-title {
    font-size: 18px;
    margin-top: 20px; /* Added margin to push the title down */
  }

  .project-expand-category,
  .project-expand-description {
    font-size: 12px;
  }

  .project-expand-prev,
  .project-expand-next {
    font-size: 32px;
  }

  .project-expand-section-header {
    font-size: 20px;
  }

  .project-expand-item-subheader {
    font-size: 18px;
  }

  .project-expand-item-details {
    font-size: 14px;
  }

  .project-expand-close {
    font-size: 32px;
  }

  .project-expand-main-content {
    flex-direction: column;
  }

  .project-expand-text-section {
    width: 100%;
    padding: 10px;
  }

  .project-expand-image-section {
    display: none; /* Hide image section on tablets and below */
  }

  .toggle-button {
    display: block; /* Show toggle button */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .project-expand-modal {
    width: 100vw;
    height: 75vh; /* Adjusted to 60% for smaller height */
    border-radius: 0;
    padding: 10px;
    top: 10%; /* Start 15% from the top */
    left: 50%;
    transform: translate(-50%, 0); /* Adjust translate to center horizontally */
  }

  .project-expand-title-section {
    padding: 5px 0;
  }

  .project-expand-title-wrapper {
    padding: 0 50px; /* Ensure title is not too close to the edges */
  }

  .project-expand-title {
    font-size: 16px;
    margin-top: 20px; /* Added margin to push the title down */
  }

  .project-expand-category,
  .project-expand-description {
    font-size: 10px;
  }

  .project-expand-prev,
  .project-expand-next {
    margin-top: 2.5vh;
    font-size: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  .project-expand-prev {
    left: 10px;
  }

  .project-expand-next {
    right: 10px;
  }

  .project-expand-section-header {
    font-size: 14px;
  }

  .project-expand-item-subheader {
    font-size: 12px;
  }

  .project-expand-item-details {
    font-size: 10px;
  }

  .project-expand-close {
    font-size: 24px;
    right: 10px;
    top: 10px;
  }

  .project-expand-main-content {
    flex-direction: column;
    overflow-y: auto;
  }

  .project-expand-text-section {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .project-expand-image-section {
    display: none; /* Hide image section on mobile devices */
  }

  .toggle-button {
    display: inline-block;
    width: calc(50% - 10px); /* Each button takes 50% width minus the margin */
    margin: 5px; /* Add margin for spacing */
    text-align: center; /* Center the text inside the button */
    vertical-align: middle; /* Ensure vertical alignment */
  }

  .contact-button {
    display: none;
    width: calc(50% - 10px); /* Each button takes 50% width minus the margin */
    margin: 5px; /* Add margin for spacing */
    text-align: center; /* Center the text inside the button */
    vertical-align: middle; /* Ensure vertical alignment */
  }

  .toggle-button {
    display: block; /* Show toggle button on mobile devices */
    background-color: var(--dark-accent-color);
    color: var(--dark-text-color);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Add margin for spacing */
  }

  .toggle-button:hover {
    background-color: var(--dark-accent-color-hover);
  }

  .App.light .toggle-button {
    background-color: var(--light-accent-color);
    color: var(--light-text-color);
  }

  .App.light .toggle-button:hover {
    background-color: var(--light-accent-color-hover);
  }

  .project-expand-text-section,
  .project-expand-image-section {
    display: none; /* Hide both sections on mobile devices by default */
    width: 100%; /* Ensure sections occupy the full width */
    padding: 10px; /* Add padding for better aesthetics */
    margin-bottom: 10px; /* Add margin for spacing */
  }

  .project-expand-text-section.show,
  .project-expand-image-section.show {
    display: block; /* Show sections when toggled */
  }

  .project-expand-image-gallery {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .project-expand-section-image {
    width: 100%; /* Ensure images occupy the full width */
    height: auto;
    border-radius: 10px;
    object-fit: contain;
  }
}

/* For 1080p laptops and desktops */
@media (min-width: 1024px) {
  .project-expand-modal {
    width: 70vw;
    height: 80vh;
  }

  .project-expand-title-wrapper {
    padding: 0 60px; /* Ensure title is not too close to the edges */
  }

  .project-expand-title {
    font-size: 36px;
  }

  .project-expand-category,
  .project-expand-description {
    font-size: 20px;
  }

  .project-expand-prev,
  .project-expand-next {
    font-size: 56px;
  }

  .project-expand-section-header {
    font-size: 28px;
  }

  .project-expand-item-subheader {
    font-size: 24px;
  }

  .project-expand-item-details {
    font-size: 18px;
  }

  .project-expand-close {
    font-size: 56px;
  }
}
