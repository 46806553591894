.App.light .project-display-container {
  background-color: var(--light-background);
}

.App.light .projects-header {
  color: var(--light-text-color);
}

.App.light .project-content {
  background: var(--light-accent-opposite-color);
  color: var(--light-text-color);
  box-shadow: 0 4px 10px var(--light-shadow); /* Add drop shadow */
}

.App.light .project-title {
  color: var(--dark-text-color);
}

.App.light .project-overlay {
  background: rgba(0, 0, 0, 0.4); /* Light overlay for contrast */
}

.App.light .project-details {
  background: var(--light-accent-opposite-color);
}

.App.light .project-category {
  color: var(--light-text-color);
}

.App.light .project-description {
  color: var(--light-typewriter-color);
}

.App.light .project-icon {
  color: var(--light-icon-color);
}

.App.dark .project-display-container {
  background-color: var(--dark-background);
}

.App.dark .projects-header {
  color: var(--dark-text-color);
}

.App.dark .project-content {
  background: var(--dark-accent-opposite-color);
  color: var(--dark-text-color);
  box-shadow: 0 4px 10px var(--dark-shadow); /* Add drop shadow */
}

.App.dark .project-title {
  color: var(--dark-text-color);
}

.App.dark .project-overlay {
  background: rgba(0, 0, 0, 0.4); /* Light overlay for contrast */
}

.App.dark .project-details {
  background: var(--dark-accent-opposite-color);
}

.App.dark .project-category {
  color: var(--dark-text-color);
}

.App.dark .project-description {
  color: var(--dark-accent-color);
}

.App.dark .project-icon {
  color: var(--dark-icon-color);
}

.project-display-container {
  width: 100%;

  overflow: hidden; /* Hide vertical scrollbar */
}

.projects-header {
  font-size: 2vw;
  position: relative; /* Add position relative to enable z-index */
  z-index: 10; /* Adjust z-index to make sure it appears above other elements */
  opacity: 0.5;
}

.projects-scroll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding-left: 20px; /* Ensures the first card has margin from the left */
  padding-right: 20px; /* Ensures the last card has margin from the right */
  margin-bottom: 60px; /* Add more space underneath the cards */
  scrollbar-width: none; /* Hide horizontal scrollbar for Firefox */
  padding-top: 2vh;
  padding-bottom: 4vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.projects-scroll::-webkit-scrollbar {
  display: none; /* Hide horizontal scrollbar for Webkit browsers */
}

.project-card {
  flex: 0 0 28vw; /* Base size of the cards */
  margin-right: 1.5vw; /* Increase spacing between cards */
  aspect-ratio: 16 / 9; /* Adjust the aspect ratio as needed */
  display: flex;
  flex-direction: column;
  position: relative; /* Add relative positioning for absolute children */
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
}

.project-card:first-child {
  margin-left: 20px; /* Ensure the first card starts with a margin from the left */
}

.project-card:last-child {
  margin-right: 20px; /* Ensure the last card ends with a margin from the right */
}

.project-content {
  border-radius: 50px; /* More rounded corners */
  overflow: hidden;
  text-align: left;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.5s ease, transform 0.5s ease; /* Smooth transition for shadow and scaling */
  transform-origin: center; /* Ensure scaling happens from the center */
}

.project-cover {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px; /* Rounded corners for bezel effect */
  overflow: hidden; /* Ensure content doesn't overflow bezel */
}

.project-title {
  position: absolute;
  top: 20%;
  width: 90%; /* Ensure the text has padding from the sides */
  text-align: center;
  font-size: 2vw; /* Scales with viewport width */
  font-weight: bold;
  z-index: 2;
  margin: 0;
  word-wrap: break-word; /* Ensure long words break to fit within the container */
  white-space: normal; /* Allow text to wrap */
  left: 5%; /* Center the text with padding on the sides */
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area */
  z-index: 1;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.project-details {
  padding: 15px 20px;
  display: flex;
  align-items: center; /* Center align the icon vertically */
  justify-content: flex-start;
  border-radius: 0 0 50px 50px; /* Round the bottom corners */
  padding-left: 5%; /* Position icon 15% from the left */
}

.project-icon {
  font-size: 1.5vw; /* Scales with viewport width */
  margin-right: 5%; /* Space text 10% from the icon */
}

.project-text {
  display: flex;
  flex-direction: column; /* Stack category and description vertically */
}

.project-category {
  font-size: 1vw; /* Scales with viewport width */
  font-weight: bold; /* Differentiates the font weight */
  margin-bottom: 5px; /* Space between category and description */
}

.project-description {
  font-size: 0.8vw; /* Scales with viewport width */
  font-weight: bold;
}

/* Hover effects for drop shadows */
.App.light .project-content:hover {
  box-shadow: 0 8px 20px var(--light-shadow); /* Darker shadow on hover for light mode */
  transform: scale(1.2); /* Smooth scaling on hover */
}

.App.dark .project-content:hover {
  box-shadow: 0 8px 20px var(--dark-shadow); /* Darker shadow on hover for dark mode */
  transform: scale(1.2); /* Smooth scaling on hover */
}

/* Dock magnify effect */
.projects-scroll:hover .project-card .project-content {
  transform: scale(0.8);
  margin-right: 1vw; /* Reduced spacing during animation */
}

.projects-scroll:hover .project-card .project-content:hover {
  transform: scale(1.2); /* Increase scaling on hover */
  margin-right: 1vw; /* Keep the margin consistent during hover */
}
/* Media Queries */
@media (max-width: 1080px) {
  .projects-header {
    font-size: 2.5vw;
  }

  .project-card {
    flex: 0 0 50vw; /* Adjust card size for smaller screens */
    margin-right: 5vw; /* Increase spacing between cards */
    aspect-ratio: 16 / 9; /* Adjust aspect ratio for double height */
  }

  .project-title {
    font-size: 3vw; /* Increase title font size */
  }

  .project-description {
    font-size: 1.2vw; /* Increase description font size */
  }

  .project-category {
    font-size: 1.2vw; /* Increase category font size */
  }

  .project-icon {
    font-size: 2vw; /* Increase icon size */
  }

  .projects-scroll:hover .project-card .project-content {
    transform: scale(0.9);
  }

  .projects-scroll:hover .project-card .project-content:hover {
    transform: scale(1.1); /* Decrease scaling on hover */
  }
}

@media (max-width: 768px) {
  .projects-header {
    font-size: 3vw;
  }

  .project-card {
    flex: 0 0 80vw; /* Adjust card size for smaller screens */
    margin-right: 2.5vw; /* Increase spacing between cards */
  }

  .project-title {
    font-size: 5vw; /* Increase title font size */
  }

  .project-description {
    font-size: 2vw; /* Increase description font size */
  }

  .project-category {
    font-size: 2vw; /* Increase category font size */
  }

  .project-icon {
    font-size: 2.5vw; /* Increase icon size */
  }

  .projects-scroll {
    padding-left: 10vw; /* Adjust padding for smaller screens */
  }

  .projects-scroll:hover .project-card .project-content {
    transform: scale(0.95);
  }

  .projects-scroll:hover .project-card .project-content:hover {
    transform: scale(1.05); /* Decrease scaling on hover */
  }
}

@media (max-width: 480px) {
  .projects-header {
    padding-top: 10vh;
    font-size: 4vw;
  }

  .project-card {
    padding-top: 4vh;
    flex: 0 0 90vw; /* Adjust card size for smaller screens */
    margin-right: 5vw; /* Increase spacing between cards */
  }

  @media (max-width: 480px) {
    .project-title {
      font-size: 5vw;
      max-width: calc(
        100% - 10vw
      ); /* Adjust max-width to account for the margins */
      word-wrap: break-word; /* Allow breaking long words to fit within the container */
      text-align: center; /* Center the text */
      margin: 0 auto; /* Center the element itself */
      display: inline-block; /* Make the element inline-block for proper centering */
    }

    .project-cover {
      display: flex;
      justify-content: center; /* Center child elements horizontally */
      align-items: center; /* Center child elements vertically */
    }
  }

  .project-description {
    font-size: 3vw; /* Increase description font size */
  }

  .project-category {
    font-size: 3vw; /* Increase category font size */
  }

  .project-icon {
    font-size: 3vw; /* Increase icon size */
  }

  .projects-scroll {
    padding-left: 0vw; /* Adjust padding for smaller screens */
  }

  .projects-scroll:hover .project-card .project-content {
    transform: scale(0.98);
  }

  .projects-scroll:hover .project-card .project-content:hover {
    transform: scale(1.02); /* Decrease scaling on hover */
  }
}
