/* hero.css */

.App .hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 35vh;
  text-align: center;
  padding: 0 20px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.App .hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.App .hero-text h1 {
  font-size: 3em;
  font-weight: bold;
  width: 100%;
  color: inherit;
  margin-bottom: 0.5em;
}

.App .hero-text .title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  color: inherit;
}

.App .hero-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  font-size: 1.2em;
  line-height: 1.5;
  max-width: 80vw;
}

.sentence-container {
  position: relative;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60vw;
  text-align: center;
}

.sentence-container p {
  padding-top: 10vh;
  position: absolute;
  margin: 0;
  width: 100%;
  font-size: 1.5em;
  font-weight: 600;
  color: inherit;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1080px) {
  .App .hero {
    height: 40vh;
    margin-top: 40px;
  }

  .App .hero-text h1 {
    font-size: 2.5em;
  }

  .App .hero-text .title {
    font-size: 1.3em;
  }

  .App .hero-description {
    font-size: 1em;
  }

  .sentence-container {
    width: 70vw;
  }

  .sentence-container p {
    padding-top: 10vh;
    font-size: 1.3em;
  }
}

@media (max-width: 768px) {
  .App .hero {
    height: 30vh;
    margin-top: 40px;
  }

  .App .hero-text {
    height: auto;
  }

  .App .hero-text h1 {
    font-size: 2em;
  }

  .App .hero-text .title {
    font-size: 1.2em;
    margin-bottom: 5vh;
  }

  .App .hero-description {
    font-size: 0.9em;
  }

  .sentence-container {
    width: 80vw;
  }

  .sentence-container p {
    font-size: 1.2em;
    padding-top: 15vh;
  }
}
