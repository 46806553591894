.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-form-modal {
  background-color: var(--light-background);
  color: var(--light-text-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--light-shadow);
  width: 90%;
  max-width: 625px; /* 500px + 25% increase */
  display: flex; /* Added flex to handle two-column layout */
  position: relative; /* Ensure the close button is positioned relative to the modal */
}

.contact-form-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: var(--light-text-color);
  cursor: pointer;
}

.contact-form-left {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--light-border-color); /* Vertical border */
}

.contact-form-right {
  width: 75%;
  padding-left: 20px; /* Add padding to separate content from border */
}

.contact-form-left a {
  font-size: 6em; /* Size of the LinkedIn icon */
  color: var(--light-text-color);
  text-decoration: none;
}

.contact-form-modal h2 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--light-border-color);
  border-radius: 5px;
  box-sizing: border-box;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.success-message {
  color: green;
  font-size: 0.9em;
  margin-bottom: 15px;
}

button[type="submit"] {
  background-color: var(--light-accent-color);
  color: var(--light-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: var(--light-accent-color-hover);
}

/* Dark mode */
.App.dark .contact-form-modal {
  background-color: var(--dark-background);
  color: var(--dark-text-color);
  box-shadow: 0 4px 10px var(--dark-shadow);
}

.App.dark .contact-form-left {
  border-right: 2px solid var(--dark-border-color); /* Vertical border for dark mode */
}

.App.dark .contact-form-close {
  color: var(--dark-text-color);
}

.App.dark .form-group input,
.App.dark .form-group textarea {
  border: 1px solid var(--dark-border-color);
}

.App.dark .error-message {
  color: var(--dark-text-color);
}

.App.dark .success-message {
  color: var(--dark-accent-color);
}

.App.dark button[type="submit"] {
  background-color: var(--dark-accent-color);
  color: var(--dark-text-color);
}

.App.dark button[type="submit"]:hover {
  background-color: var(--dark-accent-color-hover);
}

.App.dark .contact-form-left a {
  color: var(--dark-text-color); /* LinkedIn icon color for dark mode */
}
/* Media Queries */
@media (max-width: 1080px) {
  .contact-form-modal {
    width: 95%;
    max-width: 500px; /* Adjust max width for smaller screens */
    flex-direction: column; /* Stack content vertically */
  }

  .contact-form-left,
  .contact-form-right {
    width: 100%; /* Full width for both sections */
    padding: 10px; /* Add padding to separate content */
    border-right: none; /* Remove vertical border */
    border-bottom: none;
  }

  .contact-form-left a {
    font-size: 4em; /* Decrease LinkedIn icon size */
  }

  .contact-form-right {
    padding-left: 10px; /* Adjust padding */
  }

  .contact-form-close {
    font-size: 2.5em; /* close button tablet */
  }

  .form-group input,
  .form-group textarea {
    padding: 8px; /* Adjust padding */
  }

  button[type="submit"] {
    padding: 12px 20px; /* Adjust button padding */
  }

  .App.light .contact-form-left,
  .App.light .contact-form-right {
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .contact-form-modal {
    width: 95%;
    max-width: 400px; /* Adjust max width for smaller screens */
    flex-direction: column; /* Stack content vertically */
  }

  .contact-form-left,
  .contact-form-right {
    width: 100%; /* Full width for both sections */
    padding: 10px; /* Add padding to separate content */
    border-right: none; /* Remove vertical border */
    border-bottom: none;
  }

  .contact-form-left a {
    font-size: 5em; /* Decrease LinkedIn icon size */
  }

  .contact-form-right {
    padding-left: 10px; /* Adjust padding */
  }

  .contact-form-close {
    font-size: 2.5em; /* close button tablet */
  }

  .form-group input,
  .form-group textarea {
    padding: 6px; /* Adjust padding */
  }

  button[type="submit"] {
    padding: 6px 12px; /* Adjust button padding */
  }

  .App.light .contact-form-left,
  .App.light .contact-form-right {
    border-bottom: none;
  }
}

@media (max-width: 480px) {
  .contact-form-modal {
    width: 95%;
    max-width: 320px; /* Adjust max width for smaller screens */
    flex-direction: column; /* Stack content vertically */
  }

  .contact-form-left,
  .contact-form-right {
    width: 100%; /* Full width for both sections */
    padding: 10px; /* Add padding to separate content */
    border-right: none; /* Remove vertical border */
    border-bottom: none;
  }

  .contact-form-left a {
    font-size: 4em; /* Decrease LinkedIn icon size */
  }

  .contact-form-right {
    padding-left: 5px; /* Adjust padding */
  }

  .contact-form-close {
    font-size: 2.5em; /* close button mobile*/
  }

  .form-group input,
  .form-group textarea {
    padding: 4px; /* Adjust padding */
  }

  button[type="submit"] {
    padding: 8px 20px; /* Adjust button padding */
    font-weight: bold;
    font-size: 1.5em;
  }

  .App.light .contact-form-left,
  .App.light .contact-form-right {
    border-bottom: none;
  }
}

/* Dark mode adjustments */
@media (max-width: 1080px) {
  .App.dark .contact-form-left,
  .App.dark .contact-form-right {
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .App.dark .contact-form-left,
  .App.dark .contact-form-right {
    border-bottom: none;
  }
}

@media (max-width: 480px) {
  .App.dark .contact-form-left,
  .App.dark .contact-form-right {
    border-bottom: none;
  }
}
