/* daynighttoggle.css */

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon-wrapper {
  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  font-weight: bold;
}

.icon-link:hover .nav-icon,
.icon-container:hover .nav-icon {
  cursor: pointer; /* Ensure cursor indicates clickable */
  opacity: 0;
}

.icon-link:hover .icon-text,
.icon-container:hover .icon-text {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}
