/* CSS for dark/light application text */
:root {
  /* Light mode colour scheme */
  --light-background: #f5f5f5; /* Lighter background for better contrast */
  --light-text-color: #181818; /* Slightly darker text for better readability */
  --light-border-color: #c1c1c1; /* Softer border color */
  --light-typewriter-color: #4f4f4f; /* Darker typewriter color for better contrast */
  --light-accent-color: #939393; /* Light green accent color */
  --light-accent-opposite-color: #aeaeae; /* Softer accent opposite color */
  --light-icon-color: #2e2e2e; /* Darker icon color */
  --light-circle-color: #d4e2d4; /* Adjusted circle color for better visibility */

  /* Dark mode colour scheme remains unchanged for this example */
  --dark-background: #181818;
  --dark-text-color: #e0e0e0;
  --dark-border-color: #444444;
  --dark-typewriter-color: #1e7c54;
  --dark-accent-color: #1e7c54;
  --dark-accent-opposite-color: #444444;
  --dark-icon-color: #e0e0e0;

  /* Shadow colours */
  --light-shadow: rgba(0, 0, 0, 0.1);
  --dark-shadow: rgba(0, 0, 0, 0.4);
}

/* Add spacing between sections */
section {
  margin-bottom: 40px; /* Adjust the margin as needed to separate the sections */
  width: 100%; /* Ensure sections take full width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
  overflow-x: hidden;
  background-color: #000;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure relative positioning for absolute children */
}

.particles-background {
  position: fixed; /* Fixed positioning to cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure the particles do not interfere with user interactions */
}

/* CSS for dark/light application theme */
.App.light {
  background-color: var(--light-background);
  color: var(--light-text-color);
}

.App.dark {
  background-color: var(--dark-background);
  color: var(--dark-text-color);
}

/* Media query for screens with a resolution of "Insert x resolution" and above */
@media (min-width: 1170px) and (min-height: 800px) {
  .App .hero-text h1 {
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    width: 50vw;
  }
}
